<template>
  <div class="register left-float-top ml-30">
    <div class="flex-1">
      <el-row>
        <el-col :span="8">
          <div  :style="'background-image: url('+ record.coverPath.url +');background-size:100% 100%;height: 240px;'"></div>
<!--          <div v-show="freeWatch" class="player">-->
<!--            <video-player ref="videoPlayer" class="vjs-custom-skin" :options="options" @timeupdate="onTimeupdate($event)"></video-player>-->
<!--          </div>-->
        </el-col>
        <el-col :span="16">
          <div style="margin-top: 6px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
            <span style="margin-left:20px;font-size: 24px;font-family: Microsoft YaHei;font-weight: bold;color: #333333;">{{record.name}}</span>
          </div>
          <el-row>
            <el-col :span="10" style="margin-top: 24px;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;">
              <span style="margin-left: 20px;">课程负责人 ：{{record.leader}}</span>
            </el-col>
            <el-col :span="14" style="margin-top: 29px;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;">
              <span>难易程度 ：{{ record.difficultyName }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" style="margin-top: 24px;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;">
              <span style="margin-left: 20px;">创建时间 ：{{record.ctime}}</span>
            </el-col>
            <el-col :span="14" style="margin-top: 29px;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;">
              <span class="ellipsis-multiline">简介 ：{{record.synopsis}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px;">
            <el-col :span="10">
              <el-button type="primary" style="margin-left: 20px;" @click="startLearning">开始学习</el-button>
            </el-col>
            <el-col :span="10">

            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="课程列表" name="first">
          <div v-for="(item, index) in record.resources" style="margin-top: 30px;">
            <img src="../../assets/img/video-list.png" style="height:17px;width:17px;margin-right: 20px;">
            <span style="font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">{{ item.title }}</span>
          </div>

        </el-tab-pane>
      </el-tabs>

    </div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  data(){
    return{
      activeName:'first',
      currId:'',
      record:{},
      pagination:{
        total:0,
        totalpages:1,
        current:1,
        pagesize:8,
        links:[]
      },
    }
  },
  mounted(){
    const self = this;
    self.currId = this.$route.query.id;
    this.$axios.get('/api/admin/course/'+self.currId).then(function (res) {
      if (res.msg.flag == 0) {
        self.record = res.biz||{};

      }
    }, function (res) {
    });

  },
  methods:{
    handleClick:function (){

    },
    startLearning:function (){
      let path= this.$router.resolve({ path: '/video/player',query:{id:this.currId}});

      window.open(path.href, '_blank');
    },
  }
}
</script>

<style scoped>
.ellipsis-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>